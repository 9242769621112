html {
    &::-webkit-scrollbar {
        width: 12px;
    }

    &::-webkit-scrollbar-track {
        background-color: transparent;
        border: 1.5px solid #b4b3b359;
        border-radius: 8px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #b4b3b359;
        border-radius: 8px;
    }
}
body, section.main-wrapper {
    min-height: 100vh;
}
.bodyOverlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 9000;
    background-color: rgba(0,0,0,.3);
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
   opacity: 1;
}
span.label {
    font-weight: 600;
    &::after {
        content: ":";
        position: absolute;
    }
}
span.value {
    margin-left: .5rem;
}
.nav-link {
    transition: all .5s;
    &.active, &:hover {
        color: #fff!important;
        background-color: var(--bs-primary);
    }
}

.hidden-property {
    display: none;
}
#scrollBtns {
    -webkit-transition: all 0.5s ease-out;
    -moz-transition: all 0.5s ease-out;
    -o-transition: all 0.5s ease-out;
    transition: all 0.5s ease-out;
    position: fixed;
    z-index: 99;
    bottom: 17vh;
    right: -200px;
}

table {
    tr.active-line td {
        background-color: #f8f8f8;
    }
    tr.text-bg-success {
        -webkit-transition: all 1s ease-out;
        -moz-transition: all 1s ease-out;
        -o-transition: all 1s ease-out;
        transition: all 1s ease-out;
    }
    td.qty .input-group {
        max-width: 14em;
        //max-width: 110px;
        //min-width: 110px;
    }
    tbody td {
        vertical-align: middle;

        img {
            object-fit: contain;
            background-color: white;
        }
    }
}

span.show-password {
    cursor: pointer;
}

.document-actions {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 99;
    input {
        border-radius: 0;
        background-clip: border-box;
    }
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: #fff!important;
    background-color: var(--bs-primary);
}

.pagination {
    display: -ms-flexbox;
    flex-wrap: wrap;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.action-bar {
    position: fixed;
    right: 0;
    z-index: 1030;
}

// Select 2
.input-group .select2-container{
    position: relative!important;
    flex: 1 1 auto!important;
    width: 1%!important;
    min-width: 0!important;
}
.select2-container--default .select2-results>.select2-results__options {
    max-height: 50vh!important;
}
.select2-container--default .select2-selection--multiple {
    display: block!important;
    // width: 100%!important;
    padding: 0.375rem 0.75rem!important;
    font-size: 1rem!important;
    font-weight: 400!important;
    line-height: 1.6!important;
    color: var(--bs-body-color)!important;
    background-color: var(--bs-form-control-bg)!important;
    background-clip: padding-box!important;
    border: var(--bs-border-width) solid var(--bs-border-color)!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
    border-radius: 0!important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out!important;
}
.select2-container .select2-search--inline .select2-search__field {
    margin-top: 0!important;
    margin-left: 0!important;
    height: 26px!important;
    display: block!important;
}
.select2-dropdown {
    border-color: var(--bs-border-color)!important;
}
#closeMenuIcon, #closeMenuIcon {
    transition: all 0.5s ease-out;
}

.table-variations {
    .input-group {
        min-width: 150px;
       .custom-table-input {
            min-width: 80px;
       }
    }
}
// Media
@include media-breakpoint-down(sm) {

}

@include media-breakpoint-down(md) {
    .table-variations {
        .input-group {
            .record-input-available-qty {
                width: 100%;
            }
        }
    }
    .custom-table-mobile {
        thead {
            display:none;
        }
        tbody {
            width: 100%;
            margin: auto;
            --bs-gutter-x: 1.5rem;
            --bs-gutter-y: 0;
            display: flex;
            flex-wrap: wrap;
            tr {
                display: flex;
                flex-direction: column;
                background-color: #e9ecef;
                border: 1px solid #ced4da;
                border-radius: 0.375rem;
                padding: .5rem;
                td {
                    border: none;
                    span[data-title], input[data-title], div[data-title] {
                        &::before {
                            content: attr(data-title);
                            font-weight: bold;
                            color: var(--bs-dark);
                            // display: inline-block;
                            display: inline-block;
                            margin-right: 1em;
                            // width: 100%;
                            min-width: 5em;
                            text-align: right;
                        }
                        .input-group-inline-container {
                            display: inline-block;
                        }
                    }
                }
                td.item-thumbnail {
                    text-align: center;
                }
            }
            tr.active-line {
                border: 1px solid #000000;
                background-color: #b3b5bb;
            }
        }
        &.table-variations {
            table {
                border: 1px solid #ced4da;
                border-radius: 0.375rem;
                margin-bottom: 1rem;
            }
            tbody {
                tr {
                    border: none;
                    border-radius: 0;
                    margin-bottom: 0rem;
                }
            }
        }
    }
    .date-range-group {
        span:not(.spinner-grow) {
            width: 25%!important;
            border-radius: 0!important;
            margin-bottom: 0.2rem;
        }
        input {
            width: 75%!important;
            margin-bottom: 0.2rem;
        }
        #fetchAllWithinDates {
            border-top-left-radius: 0.375rem;
            border-bottom-left-radius: 0.375rem;
            width: 100%;
        }
    }
}
@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(xl) {
    .document-actions {
        bottom: 55px;
    }
    .action-bar {
        left: 0;
        bottom: 0;
    }
    body.fixed-elements-bottom {
        margin-bottom: 80px;
        #scrollBtns {
            bottom: 20vh;
        }
    }
    //  to do
    // mobile navigation
    #main-sidebar-navigation {
        position: fixed;
        left: -100%;
        z-index: 1030;
        background: white;
        top: 0;
        bottom: 54px;
        transition: all 0.5s ease-out;
    }

    // main wrapper
    #app {
        flex: 1;
        margin-bottom: 55px;
    }
}

@include media-breakpoint-down(xxl) {

}


// min width
// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) {

}
@include media-breakpoint-up(md) {

}
@include media-breakpoint-up(lg) {

}
@include media-breakpoint-up(xl) {

 }
@include media-breakpoint-up(xxl) {
    body.fixed-elements-bottom main section.card-footer {
        margin-bottom: 80px;
    }
    .action-bar {
        top: 50%;
        -webkit-transform: translate(0,-50%);
            -ms-transform: translate(0,-50%);
                transform: translate(0,-50%);
    }
    #app section.card-body {
        margin-right:52.5px;
    }
}
